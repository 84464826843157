/** @jsx jsx */
import { redirectToBoostTicketing } from "gatsby-theme-loyalty/src/components/Login/helpers";
import useLoyaltyMember from "gatsby-theme-loyalty/src/hooks/useLoyaltyMember";
import useLoyaltySettings from "gatsby-theme-loyalty/src/hooks/useLoyaltySettings";
import React, { Fragment, memo } from "react";
import { jsx, Spinner } from "theme-ui";

import Widget from "../..";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetLoyaltySwitcher" }>;
}

const LoyaltySwitcherWidget: React.FC<Props> = ({
  widget,
  data,
  isFirst,
  isLast,
  isNested,
  containerSize,
  nextWidget,
}) => {
  const loyaltySettings = useLoyaltySettings();
  const [member, memberLoading] = useLoyaltyMember();
  const shape = widget.loyaltySwitcherShape;
  const widgets = member ? shape?.signed : shape?.guest;

  if (memberLoading) {
    return (
      <div sx={{ textAlign: "center" }}>
        <Spinner size={"2rem"} />
      </div>
    );
  }

  redirectToBoostTicketing(loyaltySettings, member?.encryptedSessionId);

  return (
    <Fragment>
      {widgets?.map((nestedWidget, nestedWidgetIndex) => {
        if (!nestedWidget) {
          return null;
        }

        const _isFirst = nestedWidgetIndex === 0;
        const _isLast = nestedWidgetIndex === widgets.length - 1;
        const _nextWidget = _isLast
          ? nextWidget
          : widgets[nestedWidgetIndex + 1];

        return (
          <Widget
            key={nestedWidget.id}
            widget={nestedWidget}
            data={data}
            isFirst={isFirst ? _isFirst : false}
            isNested={isNested}
            isLast={isLast ? _isLast : false}
            nextWidget={_nextWidget}
            containerSize={containerSize}
          />
        );
      })}
    </Fragment>
  );
};

export default memo(LoyaltySwitcherWidget);
